import Script from "next/script";

export default function ScriptsComponent() {
  return (
    <>
      <Script src="https://ajax.googleapis.com/ajax/libs/jquery/3.3.1/jquery.min.js" />

      <Script
        strategy="lazyOnload"
        src="https://designs.hubtel.com/v3/app/dist/scripts/main.js"
      />

      <Script
        strategy="lazyOnload"
        defer
        src="https://static.cloudflareinsights.com/beacon.min.js/v652eace1692a40cfa3763df669d7439c1639079717194"
        integrity="sha512-Gi7xpJR8tSkrpF7aordPZQlW2DLtzUlZcumS8dMQjwDHEnw9I7ZLyiOj/6tZStRBGtGgN6ceN6cMH8z7etPGlw=="
        data-cf-beacon='{"rayId":"6d6209a9ba3072b5","token":"5f4f22e669d54423a93b2e43037c9d74","version":"2021.12.0","si":100}'
        crossOrigin="anonymous"
      />

      <Script
        src="https://code.jquery.com/jquery-3.6.0.min.js"
        integrity="sha256-/xUj+3OJU5yExlq6GSYGSHk7tPXikynS7ogEvDej/m4="
        crossOrigin="anonymous"
      />
      <Script
        src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.9.2/dist/umd/popper.min.js"
        integrity="sha384-IQsoLXl5PILFhosVNubq5LC7Qb9DXgDA9i+tQ8Zj3iwWAwPtgFTxbJ8NT4GN1R8p"
        crossOrigin="anonymous"
      />
      <Script
        src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.min.js"
        integrity="sha384-cVKIPhGWiC2Al4u+LWgxfKTRIcfu0JTxR+EQDz/bgldoEyl4H0zUF0QKbrJ0EcQF"
        crossOrigin="anonymous"
      />
      <Script type="text/javascript" src="/scripts/new-relic.js" defer />
      <Script type="text/javascript" src="/scripts/clarity.js" defer />
    </>
  );
}
